/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addLocation = /* GraphQL */ `
  mutation AddLocation(
    $first_name: String!
    $last_name: String!
    $room_number: String
    $tags: [Int]
    $picture: String
  ) {
    addLocation(
      first_name: $first_name
      last_name: $last_name
      room_number: $room_number
      tags: $tags
      picture: $picture
    ) {
      id
      first_name
      last_name
      room_number
      wash_separate
      allergy
      organization_id
      items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      location_items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      tags {
        id
        name
        type
      }
      picture
      signedPicture
      signedThumbnail
      is_archived
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $id: Int!
    $first_name: String!
    $last_name: String!
    $room_number: String
    $tags: [Int]
    $picture: String
  ) {
    updateLocation(
      id: $id
      first_name: $first_name
      last_name: $last_name
      room_number: $room_number
      tags: $tags
      picture: $picture
    ) {
      id
      first_name
      last_name
      room_number
      wash_separate
      allergy
      organization_id
      items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      location_items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      tags {
        id
        name
        type
      }
      picture
      signedPicture
      signedThumbnail
      is_archived
    }
  }
`;
export const archiveLocation = /* GraphQL */ `
  mutation ArchiveLocation($id: Int!, $archiveItems: Boolean!) {
    archiveLocation(id: $id, archiveItems: $archiveItems) {
      id
      first_name
      last_name
      room_number
      wash_separate
      allergy
      organization_id
      items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      location_items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      tags {
        id
        name
        type
      }
      picture
      signedPicture
      signedThumbnail
      is_archived
    }
  }
`;
export const addItem = /* GraphQL */ `
  mutation AddItem(
    $name: String
    $rfid_tag: String
    $resident_id: Int!
    $wash_special: String
    $dry_special: String
    $picture: String
  ) {
    addItem(
      name: $name
      rfid_tag: $rfid_tag
      resident_id: $resident_id
      wash_special: $wash_special
      dry_special: $dry_special
      picture: $picture
    ) {
      id
      name
      rfid_tag
      resident_id
      wash_special
      dry_special
      picture
      signedPicture
      signedThumbnail
      last_seen_on
      item_type_id
      item_type {
        id
        description
      }
      location_resident_id
      tags {
        id
        name
        type
      }
      sku
      size
      supplier
      material
      date
      cost
      supplier_sku
      is_archived
    }
  }
`;
export const addItemOffline = /* GraphQL */ `
  mutation AddItemOffline(
    $id: String!
    $name: String
    $rfid_tag: String
    $resident_id: Int!
    $wash_special: String
    $dry_special: String
    $picture: String
    $item_type_id: String
    $sku: String
    $size: String
    $supplier: String
    $material: String
    $date: String
    $cost: Float
    $supplier_sku: String
    $tags: [Int]
  ) {
    addItemOffline(
      id: $id
      name: $name
      rfid_tag: $rfid_tag
      resident_id: $resident_id
      wash_special: $wash_special
      dry_special: $dry_special
      picture: $picture
      item_type_id: $item_type_id
      sku: $sku
      size: $size
      supplier: $supplier
      material: $material
      date: $date
      cost: $cost
      supplier_sku: $supplier_sku
      tags: $tags
    ) {
      id
      name
      rfid_tag
      resident_id
      wash_special
      dry_special
      picture
      signedPicture
      signedThumbnail
      last_seen_on
      item_type_id
      item_type {
        id
        description
      }
      location_resident_id
      tags {
        id
        name
        type
      }
      sku
      size
      supplier
      material
      date
      cost
      supplier_sku
      is_archived
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $id: Int!
    $name: String
    $rfid_tag: String
    $wash_special: String
    $dry_special: String
    $picture: String
  ) {
    updateItem(
      id: $id
      name: $name
      rfid_tag: $rfid_tag
      wash_special: $wash_special
      dry_special: $dry_special
      picture: $picture
    ) {
      id
      name
      rfid_tag
      resident_id
      wash_special
      dry_special
      picture
      signedPicture
      signedThumbnail
      last_seen_on
      item_type_id
      item_type {
        id
        description
      }
      location_resident_id
      tags {
        id
        name
        type
      }
      sku
      size
      supplier
      material
      date
      cost
      supplier_sku
      is_archived
    }
  }
`;
export const updateItemOffline = /* GraphQL */ `
  mutation UpdateItemOffline(
    $id: String!
    $name: String
    $rfid_tag: String
    $wash_special: String
    $dry_special: String
    $picture: String
    $item_type_id: String
    $sku: String
    $size: String
    $supplier: String
    $material: String
    $date: String
    $cost: Float
    $supplier_sku: String
    $tags: [Int]
  ) {
    updateItemOffline(
      id: $id
      name: $name
      rfid_tag: $rfid_tag
      wash_special: $wash_special
      dry_special: $dry_special
      picture: $picture
      item_type_id: $item_type_id
      sku: $sku
      size: $size
      supplier: $supplier
      material: $material
      date: $date
      cost: $cost
      supplier_sku: $supplier_sku
      tags: $tags
    ) {
      id
      name
      rfid_tag
      resident_id
      wash_special
      dry_special
      picture
      signedPicture
      signedThumbnail
      last_seen_on
      item_type_id
      item_type {
        id
        description
      }
      location_resident_id
      tags {
        id
        name
        type
      }
      sku
      size
      supplier
      material
      date
      cost
      supplier_sku
      is_archived
    }
  }
`;
export const deleteItemOffline = /* GraphQL */ `
  mutation DeleteItemOffline($id: String!) {
    deleteItemOffline(id: $id)
  }
`;
export const logScan = /* GraphQL */ `
  mutation LogScan($scanner_serial: String, $item_ids: [Int]) {
    logScan(scanner_serial: $scanner_serial, item_ids: $item_ids)
  }
`;
export const logScanOffline = /* GraphQL */ `
  mutation LogScanOffline(
    $scanner_serial: String
    $reason: String
    $item_ids: [String]
  ) {
    logScanOffline(
      scanner_serial: $scanner_serial
      reason: $reason
      item_ids: $item_ids
    )
  }
`;
export const addTag = /* GraphQL */ `
  mutation AddTag($name: String!, $type: String!) {
    addTag(name: $name, type: $type) {
      id
      name
      type
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag($id: String!) {
    deleteTag(id: $id)
  }
`;
export const addItemType = /* GraphQL */ `
  mutation AddItemType($id: String!, $name: String!) {
    addItemType(id: $id, name: $name) {
      id
      description
    }
  }
`;
export const deleteItemType = /* GraphQL */ `
  mutation DeleteItemType($id: String!) {
    deleteItemType(id: $id)
  }
`;

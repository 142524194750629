import React, { Component, useState, useEffect } from 'react';
import { InputGroup, Container, Row, Col, Image, Form, FormLabel, FormControl } from 'react-bootstrap';
import { apiGet } from '../Api/Api';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import dayjs from 'dayjs';

const m = require('moment');

const { SearchBar, ClearSearchButton } = Search;

const { ExportCSVButton } = CSVExport;

function Scans() {
    const [username, setUsername] = useState()
    const [data, setData] = useState();
    const [isSimsortAdmin, setIsSimsortAdmin] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    let items = [];
    let orgSelectItems = [];

    useEffect(() => {
        async function load() {
            console.log('initial');

            let data = await getCurrentUser();
            setUsername(data.username);

            const session = await fetchAuthSession();
            const groups = session.tokens.accessToken.payload["cognito:groups"];
            const isAdmin = groups.includes('SimsortAdmin');

            setIsSimsortAdmin(isAdmin);

            if (isAdmin) {
                let orgsrequest = await apiGet('/organizations');
                setOrganizations(orgsrequest.data);
            }

            const today = dayjs();
            setEndDate(today.format('YYYY-MM-DD'));

            setStartDate(today.subtract(7, 'day').format('YYYY-MM-DD'));
        }

        load();
    }, []);

    useEffect(() => {
        async function load() {
            console.log('dates');

            if (startDate && endDate) {
                let path = `/reporting?startDate=${startDate}&endDate=${endDate}&offset=${dayjs().utcOffset()}`;

                let reportingData = await apiGet(path);

                setData(reportingData.data);
            }
        }

        load();
    }, [startDate, endDate]);

    async function changeOrg(x, y, z) {
        const id = x.target.value;

        setData([]);

        let path = `/reporting/${id}?startDate=2024-01-01&endDate=2024-02-29`;

        let reportingData = await apiGet(path);

        setData(reportingData.data);
    }

    function imageFormatter(cell, row) {
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ width: '140px', padding: '12px 12px 12px 0' }}>
                    <div className="simsort-thumb">
                        <LazyLoadImage src={row.signedPicture} className="img-fluid" />
                    </div>
                </div>
                <div style={{ padding: '12px' }}>
                    {cell}
                </div>
            </div>
        );
    }

    const columns = [{
        dataField: 'home_location_name',
        text: 'Home Location',
        formatter: (cell, row) => {
            return (
                <a href={`/resident/${row.home_location_id}`}>{cell}</a>
            );
        },
        sort: true,
        searchable: true
    },
    {
        dataField: 'room_number',
        text: 'Home Room #',
        sort: true,
        searchable: true
    },
    {
        dataField: 'location_name',
        text: 'Scan Location',
        formatter: (cell, row) => {
            return (
                <a href={`/resident/${row.location_id}`}>{cell}</a>
            );
        },
        sort: true,
        searchable: true
    },
    {
        dataField: 'name',
        text: 'Item',
        formatter: imageFormatter,
        searchable: true,
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '300px' };
        }
    },
    {
        dataField: 'rfid_tag',
        text: 'RFID Tag',
        sort: true,
        searchable: true
    },
    {
        dataField: 'scan_date',
        text: 'Scan Date',
        searchable: false,
        sort: true,
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
            if (order === 'asc') {
                return rowA.sortableDate.diff(rowB.sortableDate);
            }
            return rowB.sortableDate.diff(rowA.sortableDate);
        }
    },
    {
        dataField: 'reason',
        text: 'Reason',
        sort: true,
        searchable: true
    }];

    const defaultSorted = [{
        dataField: 'scan_date',
        order: 'dsc'
    }];

    function onColumnMatch({
        searchText,
        value,
        column,
        row
    }) {
        if (!searchText) {
            return true;
        }

        let terms = searchText.toLowerCase().split(" ");

        for (let index = 0; index < terms.length; index++) {
            const term = terms[index];

            let termMatch = false

            if (row.home_location_name && row.home_location_name.toLowerCase().search(term) > -1) {
                termMatch = true;
            }

            if (row.location_name && row.location_name.toLowerCase().search(term) > -1) {
                termMatch = true;
            }

            if (row.name && row.name.toLowerCase().search(term) > -1) {
                termMatch = true;
            }

            if (row.room_number && row.room_number.toLowerCase().search(term) > -1) {
                termMatch = true;
            }

            if (row.reason && row.reason?.toLowerCase().search(term) > -1) {
                termMatch = true;
            }

            if (!termMatch) {
                return false;
            }
        }

        return true;
    }


    if (data) {
        let c = localStorage.getItem('configData');
        let config = c != null ? JSON.parse(c) : null;

        // return (
        //     <div className="simsort-thumb">
        //         <Image src={picture} fluid />
        //     </div>
        // );

        data.forEach(item => {
            item.sortableDate = m(item.scan_date);
            item.scan_date = m(item.scan_date).format('L h:mm:ss a');

            if (config && item.picture) {
                item.signedPicture = `${config.url}/fit-in/150x150/${item.picture}?Key-Pair-Id=${config.cookies['CloudFront-Key-Pair-Id']}&Signature=${config.cookies['CloudFront-Signature']}&Policy=${config.cookies['CloudFront-Policy']}&Expires=${config.expires}`;
            }

            items.push(item);
        });
    }

    if (organizations) {
        organizations.forEach(o => {
            orgSelectItems.push(<option key={o.id} value={o.id}>{o.name}</option>);
        })
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h2>Scan Report</h2>



                    <ToolkitProvider
                        keyField="id"
                        data={items}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        search={{ onColumnMatch }}
                        exportCSV
                    >
                        {
                            props => (
                                <div>
                                    {isSimsortAdmin &&
                                        <Row>
                                            <Col sm={{ span: 3 }}>
                                                <InputGroup size="sm" className="mb-3">

                                                    <Form.Control as="select" custom onChange={changeOrg}>
                                                        {orgSelectItems}
                                                    </Form.Control>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    }
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                                            <div style={{ marginRight: '12px' }}>
                                                <FormLabel>Start Date</FormLabel>
                                                <FormControl type="date" name="startDate" value={startDate} onChange={(event) => setStartDate(event.target.value)} />
                                            </div>

                                            <div style={{ marginRight: '12px' }}>
                                                <FormLabel>End Date</FormLabel>
                                                <FormControl type="date" name="endDate" value={endDate} onChange={(event) => setEndDate(event.target.value)} />
                                            </div>

                                            <div style={{ marginRight: '12px', marginTop: '30px' }}>
                                                <ExportCSVButton {...props.csvProps}>Export CSV</ExportCSVButton>
                                            </div>
                                        </div>

                                        <div style={{ marginBotton: '-8px' }}>
                                            <SearchBar {...props.searchProps} />
                                            <ClearSearchButton {...props.searchProps} />
                                        </div>
                                    </div>

                                    <hr />
                                    <BootstrapTable classes="table-striped" bootstrap4
                                        {...props.baseProps}
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </Col>
            </Row>
        </Container>
    );
}

export default Scans;
import React from 'react'
import { fetchAuthSession } from "@aws-amplify/core";
import { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";


function Join() {
    const [state, setState] = useState({ Name: '', InviteCode: '', error: '', message: '', loading: false });

    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            [name]: value
        });
    }

    const handleSubmit = async (e) => {
        setState({
            ...state,
            loading: true
        });

        e.preventDefault();

        const url = process.env.REACT_APP_NEW_API_URL;

        try {
            await fetch(`${url}/api/Join`, {
                method: 'post',
                body: JSON.stringify({ Code: state.InviteCode }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`
                }
            });

            setState({
                ...state,
                loading: true,
                message: 'Organization Saved'
            });
        } catch (error) {
            setState({
                ...state,
                loading: false,
                message: 'Error Saving'
            });

            console.log(error);
        }

    }

    return (
        <Container>
            <h2>Join SimSort</h2>

            <Row>
                <Col sm={{ offset: 3, span: 4 }}>
                    {state.error && <Alert className='mt-3' variant="danger">{state.error}</Alert>}
                    {state.message && <Alert className='mt-3' variant="success">{state.message}</Alert>}

                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="name">
                            <Form.Label>Invite Code</Form.Label>
                            <Form.Control type="text" placeholder="Invite Code" name="InviteCode" value={state.InviteCode} onChange={handleChange} required />
                        </Form.Group>

                        <Form.Group>
                            <Button variant="primary" type="submit" disabled={state.loading}>Join</Button>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default Join
import React from 'react';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ResidentImage from './Resident/ResidentImage';

export class ResidentRow extends React.Component {
  render() {
    const resident = this.props.resident;
    return (<tr>
      <td>
        <div style={{ width: '140px' }}>
          <ResidentImage resident={resident} />
        </div>
      </td>
      <td>{resident.first_name} {resident.last_name}</td>
      <td>
        <LinkContainer to={`/resident/${resident.id}`}>
          <Button variant="link">View</Button>
        </LinkContainer>

        {/* <LinkContainer to={`/resident/edit/${resident.id}`}>
          <Button variant="link">Edit</Button>
        </LinkContainer> */}
      </td>
    </tr>);
  }
}

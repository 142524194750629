import React from 'react';
import { ResidentRow } from "./ResidentRow";
import Table from "react-bootstrap/Table";
export class ResidentTable extends React.Component {
  render() {
    const filterText = this.props.filterText;
    const rows = [];
    if (this.props.residents != null) {
      this.props.residents.forEach((resident) => {
        if (resident.first_name.toUpperCase().indexOf(filterText.toUpperCase()) === -1 && resident.last_name.toUpperCase().indexOf(filterText.toUpperCase()) === -1) {
          return;
        }
        rows.push(<ResidentRow resident={resident} key={resident.id} />);
      });
    }
    return (
    
    <Table striped bordered hover>
      <thead>
        <tr>
          <th style={{width: '140px'}}></th>
          <th>Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>);
  }
}

import React from 'react';
import { Image } from "react-bootstrap";

function ResidentImage(props) {
    let picture = props.resident ? props.resident.picture : '';

    if (picture) {
        let c = localStorage.getItem('configData');

        if (c) {
            let config = JSON.parse(c);
            picture = `${config.url}/fit-in/150x150/${picture}?Key-Pair-Id=${config.cookies['CloudFront-Key-Pair-Id']}&Signature=${config.cookies['CloudFront-Signature']}&Policy=${config.cookies['CloudFront-Policy']}&Expires=${config.expires}`;

            return (
                <div className="simsort-thumb">
                    <Image src={picture} fluid />
                </div>
            );
        }
    }

    return (<span>(No Picture)</span>);
}

export default ResidentImage;
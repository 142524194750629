
import React from 'react';
import Button from 'react-bootstrap/Button';
import { ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

export default class UserRow extends React.Component {

  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);
  }

  delete(e) {
    this.props.onDelete(this.props.user);
  }

  render() {
    const buttonStyle = { marginLeft: '.5em' }
    return (
      <tr>
        <td>{this.props.user.Username}</td>
        <td>
          <ButtonToolbar>
            {/* <Button variant="outline-primary" size="sm" style={buttonStyle}>Reset Password</Button> */}
            {(() => {
              if (this.props.username !== this.props.user.Username) {
                return (
                  <div>
                    <LinkContainer to={`/users/edit/${this.props.user.Attributes[0].Value}`}>
                      <Button variant="link">Edit</Button>
                    </LinkContainer>

                    <Button variant="outline-danger" size="sm" style={buttonStyle} onClick={this.delete}>Delete</Button>
                  </div>
                )
              } else {
                return <em>Your account</em>
              }
            })()}

          </ButtonToolbar>
        </td>
      </tr>
    );
  }
}

import React, { useEffect, useState } from 'react';
import './App.css';
import { Amplify } from 'aws-amplify';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import {
  Residents
} from './Residents';
import { Users } from './Users/Users';
import Header from './Header';
import AddUser from './Users/AddUser';
import EditUser from './Users/EditUser';
import AddResident from './Resident/AddResident';
import EditResident from './Resident/EditResident';
import Resident from './Resident';
import Reports from './Reports/Scans';

import { Authenticator as AwsAuthenticator } from '@aws-amplify/ui-react';

import "bootstrap/dist/css/bootstrap.min.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Scans from './Scans';
import { fetchAuthSession, signUp } from 'aws-amplify/auth';
import Join from './Join';
import { Hub } from '@aws-amplify/core';
import { apiGet } from './Api/Api';

const App = () => {
  const [state, setState] = useState({ loaded: false, hasOrg: false });

  useEffect(() => {
    Hub.listen('auth', async ({ payload }) => {
      console.log(payload);

      const { event } = payload;
      if (event === 'autoSignIn') {
        //const user = payload.data;
        // assign user
      } else if (event === 'autoSignIn_failure') {
        // redirect to sign in page
      } else if (event === 'signedIn') {
        console.log('signed in, reloading claims');
        await load();
      }
    })

    async function load() {
      let org = null;

      try {
        const session = await fetchAuthSession();
        if (session.tokens?.idToken) {
          org = session.tokens.idToken.payload["org_id"];

          const configData = await apiGet('/cookie');
          localStorage.setItem('configData', JSON.stringify(configData));
        }
      } catch (error) {
        console.log('error getting org id');
        console.log(error);
      }

      setState({
        ...state,
        loaded: true,
        hasOrg: !!org
      });
    }

    load();
  }, []);

  return (
    <AwsAuthenticator hideSignUp={true} services={{
      handleSignUp: async (formData) => {
        let { username, password } = formData;

        username = username.toLowerCase();

        return signUp({
          username,
          password,
          options: {
            userAttributes: {
              email: username
            },
            autoSignIn: {
              enabled: true
            }
          }
        });
      },
    }} components={{
      Header() {
        return (
          <div style={{ margin: '20px auto', textAlign: 'center' }}>
            <img
              alt=""
              src="/images/simsort_logo_500.png"
              width="200"
              className="d-inline-block align-top"
            />
            <p style={{ marginTop: '18px' }}>Important: Username (email) is <strong>case-sensitive</strong>!</p>
          </div>
        )
      }
    }}>
      <Router>
        <div className="wrapper d-flex flex-column">
          <Header />

          <div className="main">
            {state.loaded && state.hasOrg &&
              <Switch>
                <Route path='/reports' component={Reports} />
                <Route path='/resident/edit/:id' component={EditResident} />
                <Route path='/resident/:id' component={Resident} />
                <Route path='/residents/add' component={AddResident} />
                <Route path='/residents' component={Residents} />
                <Route path='/users/add' component={AddUser} />
                <Route path='/users/edit/:id' component={EditUser} />
                <Route path='/users' component={Users} />
                <Route path='/scans' component={Scans} />
                {/* <Route path='/organizations/add' component={Add} />
                <Route path='/organizations/edit/:id' component={EditOrganization} />
                <Route path='/organizations' component={Organizations} /> */}
                <Route path='/join' component={Join} />
                <Route path='/' component={Residents} />
              </Switch>
            }

            {state.loaded && !state.hasOrg &&
              <Switch>
                <Route path='/' component={Join} />
              </Switch>
            }
          </div>
          <footer>
            <p>Copyright &copy; 2024 SimSort, LLC</p>
          </footer>
        </div>
      </Router>
    </AwsAuthenticator>
  );
}

const url = process.env.REACT_APP_API_URL;
Amplify.configure({
  API: {
    GraphQL: {
      endpoint: process.env.REACT_APP_GRAPHQL_URL,
      region: 'us-east-2',
      defaultAuthMode: 'userPool'
    },
    REST: {
      'users': {
        endpoint: url,
        region: 'us-east-2'
      }
    }
  },

  Auth: {
    Cognito: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      //identityPoolRegion: 'XX-XXXX-X',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,

      allowGuestAccess: false,

    },


    // REQUIRED - Amazon Cognito Region
    region: 'us-east-2',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: false
    },

    // OPTIONAL - customized storage object
    //storage: new MyStorage(),

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH'
  }
});

export default App;
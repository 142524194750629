import React, { Component } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button, Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom'
import { apiGet, apiPost } from '../Api/Api';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';

export class EditUser extends Component {
    constructor(props) {
        super(props);

        this.state = { data: { email: '', isAdmin: false } };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                [name]: value
            }
        }));
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (!this.state.loading) {
            this.setState({ loading: true, error: null });

            try {
                await apiPost('/users/edit', { id: this.state.data.id, email: this.state.data.email, isAdmin: this.state.data.isAdmin });

                this.setState({ loading: false, error: null });
                this.props.history.push('/users');
            } catch (err) {
                this.setState({ loading: false, error: (err.response && err.response.data && err.response.data.message) ? err.response.data.message : 'Could not edit user. Please try again.' });
            }
        }
    }

    async componentDidMount() {
        const session = await fetchAuthSession();

        if (!session.tokens.accessToken?.payload["cognito:groups"].includes('Admin')) {
            this.props.history.push('/');
        }

        const { id } = this.props.match.params;

        const user = await getCurrentUser();;

        this.setState({
            username: user.username
        });

        const u = await apiGet(`/users/edit/${id}`);
        this.setState({ data: u });
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col sm={{ offset: 3, span: 4 }}>
                        <h2>Edit User</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ offset: 3, span: 4 }}>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control plaintext readOnly defaultValue={this.state.data.email} />
                            </Form.Group>

                            <Form.Check
                                checked={this.state.data.isAdmin}
                                onChange={this.handleChange}
                                name='isAdmin'
                                type='checkbox'
                                id='isAdmin'
                                label='Admin Access' />

                            <Form.Group style={{ marginTop: '12px' }}>
                                <LinkContainer to="/users" style={{ marginRight: '.5em' }}>
                                    <Button variant="link" type="button">Cancel</Button>
                                </LinkContainer>

                                <Button variant="primary" type="submit" disabled={this.state.loading}>Save</Button>
                            </Form.Group>
                            {this.state.error && <Alert variant="danger">{this.state.error}</Alert>}
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(EditUser);

import React from 'react';
import Table from "react-bootstrap/Table";
import UserRow from './UserRow';

export class UsersTable extends React.Component {
  constructor(props) {
    super(props);

    this.delete = this.delete.bind(this);
  }

  delete(user) {
    this.props.onDelete(user);
  }

  render() {
    const rows = [];

    if (this.props.users != null) {
      this.props.users.forEach((user) => {
        rows.push(<UserRow username={this.props.username} onDelete={this.delete} user={user} key={user.Username} />);
      });
    }
    return (

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>);
  }
}

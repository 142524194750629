import React, { Component } from 'react'
import * as queries from './graphql/queries';
//import { graphql } from 'aws'
import { FilterableResidentTable } from './FilterableResidentTable';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';

export class Residents extends Component {


    state = {
        username: '',
    }
    async componentDidMount() {
        const data = await getCurrentUser();

        this.setState({
            username: data.username
        });

        const client = generateClient();

        var session = await fetchAuthSession();
        const r = await client.graphql({ query: queries.residents2, variables: { withItems: false } }, {
            Authorization: session.tokens.idToken
        });
        console.log(r);
        this.setState({ data: r.data.residents2 });

        // graphql(graphqlOperation(queries.residents2, { withItems: false })).then((data) => {
        //     console.log(data);
        //     this.setState({ data: data.data.residents2 });
        // });

    }
    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        {/* <h1>Welcome {this.state.username}</h1> */}
                        <h2>Locations</h2>

                        {/* <p>
                            <LinkContainer to="/residents/add">
                                <Button variant="primary">Add Resident</Button>
                            </LinkContainer>
                        </p> */}

                        <FilterableResidentTable residents={this.state.data}></FilterableResidentTable>
                    </Col>
                </Row>
            </Container>
        )
    }
}

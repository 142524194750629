/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const resident = /* GraphQL */ `
  query Resident($id: ID!) {
    resident(id: $id) {
      id
      first_name
      last_name
      room_number
      wash_separate
      allergy
      organization_id
      items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      location_items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      tags {
        id
        name
        type
      }
      picture
      signedPicture
      signedThumbnail
      is_archived
    }
  }
`;
export const residents = /* GraphQL */ `
  query Residents {
    residents {
      id
      first_name
      last_name
      room_number
      wash_separate
      allergy
      organization_id
      items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      location_items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      tags {
        id
        name
        type
      }
      picture
      signedPicture
      signedThumbnail
      is_archived
    }
  }
`;
export const items = /* GraphQL */ `
  query Items {
    items {
      id
      name
      rfid_tag
      resident_id
      wash_special
      dry_special
      picture
      signedPicture
      signedThumbnail
      last_seen_on
      item_type_id
      item_type {
        id
        description
      }
      location_resident_id
      tags {
        id
        name
        type
      }
      sku
      size
      supplier
      material
      date
      cost
      supplier_sku
      is_archived
    }
  }
`;
export const residentOffline = /* GraphQL */ `
  query ResidentOffline($id: ID!) {
    residentOffline(id: $id) {
      id
      first_name
      last_name
      room_number
      wash_separate
      allergy
      organization_id
      items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      location_items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      tags {
        id
        name
        type
      }
      picture
      signedPicture
      signedThumbnail
      is_archived
    }
  }
`;
export const resident2 = /* GraphQL */ `
  query Resident2($id: ID!, $withItems: Boolean!) {
    resident2(id: $id, withItems: $withItems) {
      id
      first_name
      last_name
      room_number
      wash_separate
      allergy
      organization_id
      items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      location_items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      tags {
        id
        name
        type
      }
      picture
      signedPicture
      signedThumbnail
      is_archived
    }
  }
`;
export const residentsOffline = /* GraphQL */ `
  query ResidentsOffline {
    residentsOffline {
      id
      first_name
      last_name
      room_number
      wash_separate
      allergy
      organization_id
      items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      location_items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      tags {
        id
        name
        type
      }
      picture
      signedPicture
      signedThumbnail
      is_archived
    }
  }
`;
export const signedThumbnail = /* GraphQL */ `
  query SignedThumbnail {
    signedThumbnail
  }
`;
export const residents2 = /* GraphQL */ `
  query Residents2($withItems: Boolean!) {
    residents2(withItems: $withItems) {
      id
      first_name
      last_name
      room_number
      wash_separate
      allergy
      organization_id
      items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      location_items {
        id
        name
        rfid_tag
        resident_id
        wash_special
        dry_special
        picture
        signedPicture
        signedThumbnail
        last_seen_on
        item_type_id
        location_resident_id
        sku
        size
        supplier
        material
        date
        cost
        supplier_sku
        is_archived
      }
      tags {
        id
        name
        type
      }
      picture
      signedPicture
      signedThumbnail
      is_archived
    }
  }
`;
export const itemsOffline = /* GraphQL */ `
  query ItemsOffline {
    itemsOffline {
      id
      name
      rfid_tag
      resident_id
      wash_special
      dry_special
      picture
      signedPicture
      signedThumbnail
      last_seen_on
      item_type_id
      item_type {
        id
        description
      }
      location_resident_id
      tags {
        id
        name
        type
      }
      sku
      size
      supplier
      material
      date
      cost
      supplier_sku
      is_archived
    }
  }
`;
export const items2 = /* GraphQL */ `
  query Items2 {
    items2 {
      id
      name
      rfid_tag
      resident_id
      wash_special
      dry_special
      picture
      signedPicture
      signedThumbnail
      last_seen_on
      item_type_id
      item_type {
        id
        description
      }
      location_resident_id
      tags {
        id
        name
        type
      }
      sku
      size
      supplier
      material
      date
      cost
      supplier_sku
      is_archived
    }
  }
`;
export const item = /* GraphQL */ `
  query Item($id: ID!) {
    item(id: $id) {
      id
      name
      rfid_tag
      resident_id
      wash_special
      dry_special
      picture
      signedPicture
      signedThumbnail
      last_seen_on
      item_type_id
      item_type {
        id
        description
      }
      location_resident_id
      tags {
        id
        name
        type
      }
      sku
      size
      supplier
      material
      date
      cost
      supplier_sku
      is_archived
    }
  }
`;
export const itemTypes = /* GraphQL */ `
  query ItemTypes {
    itemTypes {
      id
      description
    }
  }
`;
export const tags = /* GraphQL */ `
  query Tags {
    tags {
      id
      name
      type
    }
  }
`;

import React, { useState, useEffect } from 'react'
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import { useParams } from "react-router-dom"
import { Container, Row, Col, Button, Modal, Alert } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ResidentImage from './Resident/ResidentImage';
import { fetchAuthSession } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const { SearchBar, ClearSearchButton } = Search;

function Resident() {
    const [resident, setResident] = useState();
    const [loading, setLoading] = useState();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    let { id } = useParams();
    const c = localStorage.getItem('configData');
    let config = null;
    if (c) {
        config = JSON.parse(c);
    }

    useEffect(() => {
        const client = generateClient();

        async function load() {
            var session = await fetchAuthSession();

            try {
                const r = await client.graphql({ query: queries.residentOffline, variables: { id: id } }, {
                    Authorization: session.tokens.idToken
                });
                setResident(r.data.residentOffline);
            } catch (error) {
                console.log(error);
            }
        }

        load();
    }, [])

    let name = "";
    let room = "";
    let items = [];

    const archive = (e) => {
        setShowConfirmModal(true);
    }

    const handleClose = () => setShowConfirmModal(false);

    const handleConfirmArchive = async () => {
        const client = generateClient();

        setLoading(true);

        let res = { archiveItems: true, id: id };

        const session = await fetchAuthSession();

        try {
            await client.graphql({ query: mutations.archiveLocation, variables: res }, {
                Authorization: session.tokens.idToken
            });
            handleClose();
            setError(null);
            setMessage(`Location ${resident.is_archived === true ? 'Unarchived' : 'Archived'}`);
            setLoading(false);

            setResident({
                ...resident,
                is_archived: !resident.is_archived
            });
        } catch (error) {
            handleClose();
            setError("Failed to archive location.");
            setLoading(false);
        }
    }

    function dateFormatter(cell, row) {
        if (cell) {
            return (<Moment format="L h:mm a">{cell}</Moment>)
        }

        return (<span>N/A</span>)
    }

    function imageFormatter(cell, row) {
        const picture = `${config.url}/fit-in/150x150/${row.picture}?Key-Pair-Id=${config.cookies['CloudFront-Key-Pair-Id']}&Signature=${config.cookies['CloudFront-Signature']}&Policy=${config.cookies['CloudFront-Policy']}&Expires=${config.expires}`;

        return (
            <div style={{ display: 'flex' }}>
                <div style={{ width: '140px', padding: '12px 12px 12px 0' }}>
                    <div className="simsort-thumb">
                        <LazyLoadImage src={picture} className="img-fluid" />
                    </div>
                </div>
                <div style={{ padding: '12px' }}>
                    {cell}
                </div>
            </div>
        );
    }

    const columns = [{
        dataField: 'id',
        text: 'ID',
        searchable: false,
        hidden: true
    },
    {
        dataField: 'name',
        text: 'Item',
        formatter: imageFormatter,
        searchable: true,
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '300px' };
        }
    },
    {
        dataField: 'last_seen_on',
        text: 'Last Seen',
        formatter: dateFormatter,
        searchable: false,
        sort: true
    }];

    const defaultSorted = [{
        dataField: 'name',
        order: 'asc'
    }];

    if (resident) {
        name = `${resident.first_name} ${resident.last_name}`;

        if (resident.room_number) {
            room = `Room ${resident.room_number}`;
        }

        if (resident.items) {
            resident.items.forEach(item => {
                items.push(item);
            });
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    {error && <Alert className='mt-3' variant="danger">{error}</Alert>}
                    {message && <Alert className='mt-3' variant="success">{message}</Alert>}

                    <div style={{ display: 'flex', marginTop: '10px' }}>
                        <div style={{ width: '140px', padding: '12px 12px 12px 0' }}>
                            <ResidentImage resident={resident}></ResidentImage>
                        </div>
                        <div style={{ padding: '12px' }}>
                            <h2>{name}</h2>
                            <strong>{room}</strong>
                            <p>
                                <Button variant="outline-warning" style={{ marginTop: '12px' }} size="sm" onClick={archive}
                                    disabled={loading}>{resident && resident.is_archived ? 'Unarchive' : 'Archive'}</Button>
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Inventory</h3>

                    <ToolkitProvider
                        keyField="id"
                        data={items}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        search
                    >
                        {
                            props => (
                                <div>
                                    <SearchBar {...props.searchProps} />
                                    <ClearSearchButton {...props.searchProps} />
                                    <hr />
                                    <BootstrapTable classes="table-striped" bootstrap4
                                        {...props.baseProps}
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </Col>
            </Row>

            <Modal show={showConfirmModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm</Modal.Title>
                </Modal.Header>
                {resident && resident.is_archived ?
                    <Modal.Body>Are you sure you want to unarchive this location?</Modal.Body> :
                    <Modal.Body>Are you sure you want to archive this location and all its owned items?</Modal.Body>}
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No, Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirmArchive}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default Resident;
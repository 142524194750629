import React, { Component } from 'react'
import * as mutations from '../graphql/mutations';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button, Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom'

export class AddResident extends Component {
    constructor(props) {
        super(props);

        this.state = { firstName: '', lastName: '', roomNumber: '', allergy: false, separate: false };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.loading) {
            this.setState({ loading: true, error: null });

            const res = {
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                room_number: this.state.roomNumber,
                allergy: this.state.allergy,
                wash_separate: this.state.separate,
                picture: ''
            };

            // API.graphql(graphqlOperation(mutations.addResident, res)).then(response => {
            //     console.log(response);

            //     this.setState({ loading: false, error: null });
            //     this.props.history.push('/residents');
            // }).catch(err => {
            //     this.setState({ loading: false, error: (err.response.data && err.response.data.message) ? err.response.data.message : 'Could not create user. Please try again.' });
            // });
        }
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col sm={{ offset: 3, span: 4 }}>
                        <h2>Add Resident</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ offset: 3, span: 4 }}>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="First name" name="firstName" value={this.state.firstName} onChange={this.handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Last name" name="lastName" value={this.state.lastName} onChange={this.handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="lastName">
                                <Form.Label>Room Number</Form.Label>
                                <Form.Control type="text" placeholder="Room number" name="roomNumber" value={this.state.roomNumber} onChange={this.handleChange} />
                            </Form.Group>

                            <Form.Group>
                                <div class="form-check">
                                    <input id="allergy" className="form-check-input" name="allergy" type="checkbox" checked={this.state.allergy} onChange={this.handleChange} />
                                    <label for="allergy">Special Soap</label>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <div class="form-check">
                                    <input id="separate" className="form-check-input" name="separate" type="checkbox" checked={this.state.separate} onChange={this.handleChange} />
                                    <label for="separate">Wash Separate</label>
                                </div>
                            </Form.Group>

                            <Form.Group>
                                <LinkContainer to="/residents" style={{ marginRight: '.5em' }}>
                                    <Button variant="link" type="button">Cancel</Button>
                                </LinkContainer>

                                <Button variant="primary" type="submit" disabled={this.state.loading}>Save</Button>
                            </Form.Group>
                            {this.state.error && <Alert variant="danger">{this.state.error}</Alert>}
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(AddResident);

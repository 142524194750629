import React, { Component } from 'react'
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = { isAuthenticated: false, isAdmin: false, isOrgAdmin: false };
    }

    async componentDidMount() {
        try {
            const session = await fetchAuthSession();
            const groups = session.tokens.accessToken?.payload["cognito:groups"];
            const isAdmin = groups?.includes('SimsortAdmin');
            const isOrgAdmin = groups?.includes('Admin');

            this.setState({ isAuthenticated: true, isAdmin: isAdmin, isOrgAdmin: isOrgAdmin });
        } catch (err) {
            this.setState({ isAuthenticated: false, isAdmin: false, isOrgAdmin: false });
            console.log('header check error: ');
            console.log(err);
        }
    }

    render() {

        return (
            <Navbar bg="dark" variant="dark" expand="lg">
                <LinkContainer to="/">
                    <Navbar.Brand>
                        <img
                            alt=""
                            src="/images/ss_logo.svg"
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />
                        {' SimSort'}
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    {this.state.isAuthenticated && (
                        <>
                            <Nav className="mr-auto">
                                <Nav.Item>
                                    <Link className="nav-link" to="/residents">Locations</Link>
                                </Nav.Item>
                                {this.state.isOrgAdmin && (
                                    <Nav.Item>
                                        <Link className="nav-link" to="/users">Users</Link>
                                    </Nav.Item>
                                )}
                                <Nav.Item>
                                    <Link className="nav-link" to="/reports">Reports</Link>
                                </Nav.Item>
                                {/* {this.state.isAdmin && (
                                    <Nav.Item>
                                        <Link className="nav-link" to="/organizations">Organizations</Link>
                                    </Nav.Item>
                                )} */}
                            </Nav>

                            <Nav className="justify-content-end" onSelect={() => {
                                signOut()
                            }}>
                                <Nav.Item>
                                    <Nav.Link eventKey="signOut">
                                        Sign Out
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </>
                    )}
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default withRouter(Header);
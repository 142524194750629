import React, { Component } from 'react'
import { UsersTable } from './UsersTable';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { apiDelete, apiGet } from '../Api/Api';

export class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            show: false
        }

        this.delete = this.delete.bind(this);
        this.deleteCancel = this.deleteCancel.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
    }


    delete(user) {
        this.setState({ show: true, deletingUser: user });
        console.log(user);
    }

    deleteCancel() {
        this.setState({ show: false });
    }

    async deleteConfirm() {
        this.setState({ show: false });
        let index = this.state.data.indexOf(this.state.deletingUser);
        this.state.data.splice(index, 1);

        await apiDelete('/users', { username: this.state.deletingUser.Username });
    }

    async componentDidMount() {
        const user = await getCurrentUser();;
        const session = await fetchAuthSession();

        if (!session.tokens.accessToken?.payload["cognito:groups"].includes('Admin')) {
            this.props.history.push('/');
        }

        this.setState({
            username: user.username
        });

        try {
            var d = await apiGet('/users/list');

            this.setState({ data: d.Users });
        }
        catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <>
                <Container>
                    <Row>
                        <Col>
                            <h2>Users</h2>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={8}>
                            <p>
                                <LinkContainer to="/users/add">
                                    <Button variant="primary">Add User</Button>
                                </LinkContainer>
                            </p>

                            <UsersTable username={this.state.username} users={this.state.data} onDelete={this.delete}></UsersTable>
                        </Col>
                        <Col sm={4}>
                            <p><strong>Users have the ability to:</strong></p>
                            <ul>
                                <li>Login to the SimSort app</li>
                                <li>Scan laundry items</li>
                                <li>View and manage residents</li>
                                <li>View and manage resident items</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>

                <Modal show={this.state.show} onHide={this.deleteCancel}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={this.deleteCancel}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={this.deleteConfirm}>
                            Yes, Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default withRouter(Users);
import React from 'react';
import { FormControl, InputGroup, Row, Col } from 'react-bootstrap';

export class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.handleInStockChange = this.handleInStockChange.bind(this);
  }
  handleFilterTextChange(e) {
    this.props.onFilterTextChange(e.target.value);
  }
  handleInStockChange(e) {
    this.props.onInStockChange(e.target.checked);
  }
  render() {
    const filterText = this.props.filterText;

    return (<form>
      <Row>
        <Col sm={{ span: 3 }}>
          <InputGroup size="sm" className="mb-3">
            <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder="Search..." value={filterText} onChange={this.handleFilterTextChange} />
          </InputGroup>
        </Col>
      </Row>

      {/* <input type="text" placeholder="Search..." value={filterText} onChange={this.handleFilterTextChange} /> */}
      {/* <p>
        <input type="checkbox" checked={inStockOnly} onChange={this.handleInStockChange} />
        {' '}
        Only show residents in stock?
        </p> */}
    </form>);
  }
}

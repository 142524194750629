import React from 'react';
import { SearchBar } from './SearchBar';
import { ResidentTable } from './ResidentTable';
export class FilterableResidentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: '',
      inStockOnly: false
    };
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.handleInStockChange = this.handleInStockChange.bind(this);
  }
  handleFilterTextChange(filterText) {
    this.setState({
      filterText: filterText
    });
  }
  handleInStockChange(inStockOnly) {
    this.setState({
      inStockOnly: inStockOnly
    });
  }
  render() {
    return (<div>
      <SearchBar filterText={this.state.filterText} inStockOnly={this.state.inStockOnly} onFilterTextChange={this.handleFilterTextChange} />
      <ResidentTable residents={this.props.residents} filterText={this.state.filterText} inStockOnly={this.state.inStockOnly} onInStockChange={this.handleInStockChange} />
    </div>);
  }
}

import { del, get, post } from 'aws-amplify/api';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';

export async function apiGet(path) {
    var session = await fetchAuthSession();

    let data = await get({
        apiName: 'users', path: path, options: {
            headers: {
                Authorization: session.tokens.idToken
            }
        }
    }).response;

    return await data.body.json();
}

export async function apiDelete(path, data) {
    var session = await fetchAuthSession();

    const result = await del({
        apiName: 'users', path: path, options: {
            headers: {
                Authorization: session.tokens.idToken
            },
            queryParams: data
        }
    }).response;
}

export async function apiPost(path, data) {
    var session = await fetchAuthSession();

    const result = await post({
        apiName: 'users', path: path, options: {
            headers: {
                Authorization: session.tokens.idToken
            },
            body: data
        }
    }).response;
}
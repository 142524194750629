import React, { Component } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button, Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom'
import { apiPost } from '../Api/Api';
import { fetchAuthSession } from 'aws-amplify/auth';

export class AddUser extends Component {
    constructor(props) {
        super(props);

        this.state = { name: '', email: '' };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (!this.state.loading) {
            this.setState({ loading: true, error: null });

            try {
                await apiPost('/users', { email: this.state.email, name: this.state.name });
                this.setState({ loading: false, error: null });
                this.props.history.push('/users');
            } catch (err) {
                this.setState({ loading: false, error: (err.response && err.response.data && err.response.data.message) ? err.response.data.message : 'Could not create user. Please try again.' });
            }
        }
    }

    async componentDidMount() {
        const session = await fetchAuthSession();

        if (!session.tokens.accessToken?.payload["cognito:groups"].includes('Admin')) {
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col sm={{ offset: 3, span: 4 }}>
                        <h2>Add User</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ offset: 3, span: 4 }}>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" placeholder="Email address" name="email" value={this.state.email} onChange={this.handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Name" name="name" value={this.state.name} onChange={this.handleChange} required />
                            </Form.Group>

                            <Form.Group>
                                <LinkContainer to="/users" style={{ marginRight: '.5em' }}>
                                    <Button variant="link" type="button">Cancel</Button>
                                </LinkContainer>

                                <Button variant="primary" type="submit" disabled={this.state.loading}>Save</Button>
                            </Form.Group>
                            {this.state.error && <Alert variant="danger">{this.state.error}</Alert>}
                        </Form>
                    </Col>
                    <Col sm={3}>
                        <p>An email will be sent with instructions for logging into their account.</p>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(AddUser);
